export default [
    {
      path: '/future-contracts',
      name: 'future-contracts',
      component: () => import('@/views/future-contract/FutureContract.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
    {
      path: '/future-contract/amend-details/:id',
      name: 'future-contract-amend-details',
      component: () => import('@/views/future-contract/amendDetails/AmendDetails.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings', 
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
    {
      path: '/future-contract/selected-paired-trades/:id',
      name: 'future-contract-selected-paired-trades',
      component: () => import('@/views/future-contract/getPairedTrades/PairedTrades.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings', 
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
  
  ];
  