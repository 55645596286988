import Vue from "vue";
import Vuex from "vuex";
import axiosIns from "@/libs/axios";

// Modules

import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";
import store from "@/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      name: null,
      password: null,
      role: null,
    },
    localeLanguage: {
      locale: "en",
      img: require("@/assets/images/flags/en.png"),
      name: "English",
    },
    validPermissions: null,
    runLastEquityBondSearchData: [],
    runLastEquitySearchData: [],
    runLastBondSearchData: [],
    runLastFxSpotForwardSwapSearchData:[],
    runLastTransfersSearchData:[],
    runLastFxOptionsSearchData:[],
    runLastSwapFutureSearchData:[],
    runLastJournalSearchData:[],
    runLastdepositSearchData:[],
    lastEquityBondSearchData:[],
    usersOrderColumnsEquityData:[],
    usersOrderColumnsSwapFutureData:[],
    usersOrderColumnsFxOption:[],
    usersOrderColumnsdepositData:[],
    usersOrderColumnsBondData:[],
    usersOrderColumnsTransferData:[],
    usersOrderColumnsFxData:[]
  },
  getters: {
    authUser: (state) => {
      return state.user;
    },
    getLanguage: (state) => {
      return state.localeLanguage;
    },
    validPermissions: (state) => {
      return state.validPermissions;
    },




    getUserOrderColumnsEquity: (state) => {
      let searchUser = state.usersOrderColumnsEquityData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.orderColumnsData;
    },
    getUserOrderColumnsBond: (state) => {
      let searchUser = state.usersOrderColumnsBondData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.orderColumnsData;
    },
    getUserOrderColumnsTransfer: (state) => {
      let searchUser = state.usersOrderColumnsTransferData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.orderColumnsData;
    },
    getUserOrderColumnsFx: (state) => {
      let searchUser = state.usersOrderColumnsFxData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.orderColumnsData;
    },
    getUserOrderColumnsSwapFuture: (state) => {
      let searchUser = state.usersOrderColumnsSwapFutureData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.orderColumnsData;
    },
    getUserOrderColumnsFxOption: (state) => {
      let searchUser = state.usersOrderColumnsFxOption.find(
        (data) => data.user == state.user.name
      );
      return searchUser.orderColumnsData;
    },
    getUserOrderColumnsDeposit: (state) => {
      let searchUser = state.usersOrderColumnsdepositData.find(
        (data) => data.user == state.user.name
      );
      return searchUser.orderColumnsData;
    },
  },
  mutations: {
    SET_USER(state, user) {

      // console.log(typeof state.runLastEquityBondSearchData)
      //   if (typeof state.runLastEquityBondSearchData == "object" || state.runLastEquityBondSearchData.length == 0) {
      //     state.runLastEquityBondSearchData = [];
      //   }
      
      state.user = user;
    },
    SET_LANGUAGE(state, lang) {
      state.localeLanguage = lang;
    },
    _setValidPermission(state, perm) {
      state.validPermissions = perm;
    },
    _saveLastSearchEquityBond(state, searchData) {
      store
        .dispatch("equity&bond/saveSearch", {
          auth: state.user,
          searchData: searchData,
          searchName: null,
          searchType: "EquityBond",
          LastSearch : 1,
        })
        .then((res) => {
       
        })
        .catch((error) => {
    
          console.log(error);
        });

      

      //state.runLastEquityBondSearchData = searchData
    },
    _saveLastSearchEquity(state, searchData) {


      store
        .dispatch("equity&bond/saveSearch", {
          auth: state.user,
          searchData: searchData,
          searchName: null,
          searchType: "Equity",
          LastSearch : 1,
        })
        .then((res) => {
       
        })
        .catch((error) => {
  
          console.log(error);
        });


      //state.runLastEquityBondSearchData = searchData
    },
    _saveLastSearchBond(state, searchData) {
      store
        .dispatch("equity&bond/saveSearch", {
          auth: state.user,
          searchData: searchData,
          searchName: null,
          searchType: "Bond",
          LastSearch : 1,
        })
        .then((res) => {
       
        })
        .catch((error) => {
     
          console.log(error);
        });

      //state.runLastEquityBondSearchData = searchData
    },
    _saveLastSearchFxForwardSwap(state, searchData) {
      store
      .dispatch("fxSFS/saveSearch", {
        auth: state.user,
        searchData: searchData,
        searchName: null,
        searchType: "FX",
        LastSearch : 1,
      })
      .then((res) => {
     
      })
      .catch((error) => {

        console.log(error);
      });
    },
    _saveLastSearchTransfers(state, searchData) {
      store
      .dispatch("transfers/saveSearch", {
        auth: state.user,
        searchData: searchData,
        searchName: null,
        searchType: "Transfer",
        LastSearch : 1,
      })
      .then((res) => {
     
      })
      .catch((error) => {
 
        console.log(error);
      });
    },

    _saveLastSearchJournals(state,searchData) {
      store
      .dispatch("journal/saveSearch", {
        auth: state.user,
        searchData: searchData,
        searchName: null,
        searchType: "Journal",
        LastSearch : 1,
      })
      .then((res) => {
     
      })
      .catch((error) => {
      
        console.log(error);
      });
    },
    _saveLastSearchFxOption(state,searchData) {
      store
      .dispatch("fx_options/saveSearch", {
        auth: state.user,
        searchData: searchData,
        searchName: 'last_search_fxoption',
        searchType: "FxOption",
        LastSearch : 1,
      })
      .then((res) => {
     
      })
      .catch((error) => {
  
        console.log(error);
      });
    },
    _saveLastSearchSwapFuture(state, searchData) {
      store
      .dispatch("swapFuture/saveSearch", {
        auth: state.user,
        searchData: searchData,
        searchName: null,
        searchType: "SwapFuture",
        LastSearch : 1,
      })
      .then((res) => {
     
      })
      .catch((error) => {

        console.log(error);
      });
    },

    _saveLastSearchDeposit(state, searchData) {
      store
      .dispatch("deposit/saveSearch", {
        auth: state.user,
        searchData: searchData,
        searchName: null,
        searchType: "Deposit",
        LastSearch : 1,
      })
      .then((res) => {
     
      })
      .catch((error) => {

        console.log(error);
      });
    },

    //save order columns ****************

    _saveOrderColumnsEquity(state,columnsData){
      if (state.usersOrderColumnsEquityData.length > 0) {
        let searchUser = state.usersOrderColumnsEquityData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.orderColumnsData = columnsData;
        } else {
          state.usersOrderColumnsEquityData.push({
            user: state.user.name,
            orderColumnsData: columnsData,
          });
        }
      } else {
        state.usersOrderColumnsEquityData.push({
          user: state.user.name,
          orderColumnsData: columnsData,
        });
      }
    },
    _saveOrderColumnsBond(state,columnsData){
      if (state.usersOrderColumnsBondData.length > 0) {
        let searchUser = state.usersOrderColumnsBondData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.orderColumnsData = columnsData;
        } else {
          state.usersOrderColumnsBondData.push({
            user: state.user.name,
            orderColumnsData: columnsData,
          });
        }
      } else {
        state.usersOrderColumnsBondData.push({
          user: state.user.name,
          orderColumnsData: columnsData,
        });
      }
    },
    _saveOrderColumnsTransfer(state,columnsData){
      if (state.usersOrderColumnsTransferData.length > 0) {
        let searchUser = state.usersOrderColumnsTransferData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.orderColumnsData = columnsData;
        } else {
          state.usersOrderColumnsTransferData.push({
            user: state.user.name,
            orderColumnsData: columnsData,
          });
        }
      } else {
        state.usersOrderColumnsTransferData.push({
          user: state.user.name,
          orderColumnsData: columnsData,
        });
      }
    },
    _saveOrderColumnsFx(state,columnsData){
      if (state.usersOrderColumnsFxData.length > 0) {
        let searchUser = state.usersOrderColumnsFxData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.orderColumnsData = columnsData;
        } else {
          state.usersOrderColumnsFxData.push({
            user: state.user.name,
            orderColumnsData: columnsData,
          });
        }
      } else {
        state.usersOrderColumnsFxData.push({
          user: state.user.name,
          orderColumnsData: columnsData,
        });
      }
    },
    _saveOrderColumnsSwapFuture(state,columnsData){
      if (state.usersOrderColumnsSwapFutureData.length > 0) {
        let searchUser = state.usersOrderColumnsSwapFutureData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.orderColumnsData = columnsData;
        } else {
          state.usersOrderColumnsSwapFutureData.push({
            user: state.user.name,
            orderColumnsData: columnsData,
          });
        }
      } else {
        state.usersOrderColumnsSwapFutureData.push({
          user: state.user.name,
          orderColumnsData: columnsData,
        });
      }
    },
    _saveOrderColumnsFxOption(state,columnsData){
      if (state.usersOrderColumnsFxOption.length > 0) {
        let searchUser = state.usersOrderColumnsFxOption.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.orderColumnsData = columnsData;
        } else {
          state.usersOrderColumnsFxOption.push({
            user: state.user.name,
            orderColumnsData: columnsData,
          });
        }
      } else {
        state.usersOrderColumnsFxOption.push({
          user: state.user.name,
          orderColumnsData: columnsData,
        });
      }
    },
    _saveOrderColumnsDeposit(state,columnsData){
      if (state.usersOrderColumnsdepositData.length > 0) {
        let searchUser = state.usersOrderColumnsdepositData.find(
          (data) => data.user == state.user.name
        );
        if (searchUser) {
          searchUser.orderColumnsData = columnsData;
        } else {
          state.usersOrderColumnsdepositData.push({
            user: state.user.name,
            orderColumnsData: columnsData,
          });
        }
      } else {
        state.usersOrderColumnsdepositData.push({
          user: state.user.name,
          orderColumnsData: columnsData,
        });
      }
    },
  },
  actions: {
    setUser({ commit }, newValue) {
      commit("SET_USER", newValue);
    },
    setLanguage({ commit }, newValue) {
      commit("SET_LANGUAGE", newValue);
    },
    setValidPermission({ commit }, newValue) {
      commit("_setValidPermission", newValue);
    },
    getTeamNames(context, user) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("getTeamNames", user)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getUserNames(context) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("getUserNames")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveLastSearchEquityBond({ commit }, searchData) {
      commit("_saveLastSearchEquityBond", searchData);
    },
    saveLastSearchBond({ commit }, searchData) {
      commit("_saveLastSearchBond", searchData);
    },
    saveLastSearchEquity({ commit }, searchData) {
      commit("_saveLastSearchEquity", searchData);
    },
    saveLastSearchFxForwardSwap({ commit }, searchData) {
      commit("_saveLastSearchFxForwardSwap", searchData);
    },
    saveLastSearchTransfers({ commit }, searchData) {
      commit("_saveLastSearchTransfers", searchData);
    },
    saveLastSearchJournals({ commit }, searchData) {
      commit("_saveLastSearchJournals", searchData);
    },
    saveLastSearchFxOption({ commit }, searchData) {
      commit("_saveLastSearchFxOption", searchData);
    },
    saveLastSearchSwapFuture({ commit }, searchData) {
      commit("_saveLastSearchSwapFuture", searchData);
    },
    saveLastSearchDeposit({ commit }, searchData) {
      commit("_saveLastSearchDeposit", searchData);
    },

    //order columns
    saveOrderColumnsEquity({ commit }, columnsData) {
      return new Promise((resolve, reject) => {
        if(columnsData){
          axiosIns
          .post(`saveOrderColumnsEquity`,{columnsData:columnsData, type: "Equity"})
          .then((response) => {
            resolve(response);
            commit("_saveOrderColumnsEquity", columnsData);
          })
          .catch((error) => reject(error));
        } else {
          resolve('Not found columns data')
        }
        
      });
    },
    getOrderColumnsEquity({ commit }){
      return new Promise((resolve, reject) => {
          axiosIns
          .post(`getOrderColumnsEquity`,{type: "Equity"})
          .then((res) => {
            if(res.info == 'found'){
              commit("_saveOrderColumnsEquity", res.data.data[0]);
              resolve(res.data.info);
            } else {
              resolve(res.data.info);
            }
          })
          .catch((error) => reject(error));
      });
    },

    saveOrderColumnsBond({ commit }, columnsData) {
      return new Promise((resolve, reject) => {
        if(columnsData){
          axiosIns
          .post(`saveOrderColumnsBond`,{columnsData:columnsData, type: "Bond"})
          .then((response) => {
            resolve(response);
            commit("_saveOrderColumnsBond", columnsData);
          })
          .catch((error) => reject(error));
        } else {
          resolve('Not found columns data')
        }
        
      });
    },
    getOrderColumnsBond({ commit }){
      return new Promise((resolve, reject) => {
          axiosIns
          .post(`getOrderColumnsBond`,{type: "Bond"})
          .then((res) => {
            if(res.info == 'found'){
              commit("_saveOrderColumnsBond", res.data.data[0]);
              resolve(res.data.info);
            } else {
              resolve(res.data.info);
            }
          })
          .catch((error) => reject(error));
      });
    },
    saveOrderColumnsTransfer({ commit }, columnsData) {
      return new Promise((resolve, reject) => {
        if(columnsData){
          axiosIns
          .post(`saveOrderColumnsTransfer`,{columnsData:columnsData, type: "Transfer"})
          .then((response) => {
            resolve(response);
            commit("_saveOrderColumnsTransfer", columnsData);
          })
          .catch((error) => reject(error));
        } else {
          resolve('Not found columns data')
        }
        
      });
    },
    getOrderColumnsTransfer({ commit }){
      return new Promise((resolve, reject) => {
          axiosIns
          .post(`getOrderColumnsTransfer`,{type: "Transfer"})
          .then((res) => {
            if(res.info == 'found'){
              commit("_saveOrderColumnsTransfer", res.data.data[0]);
              resolve(res.data.info);
            } else {
              resolve(res.data.info);
            }
          })
          .catch((error) => reject(error));
      });
    },
    saveOrderColumnsFx({ commit }, columnsData) {
      return new Promise((resolve, reject) => {
        if(columnsData){
          axiosIns
          .post(`saveOrderColumnsFx`,{columnsData:columnsData, type: "FxExecuted"})
          .then((response) => {
            resolve(response);
            commit("_saveOrderColumnsFx", columnsData);
          })
          .catch((error) => reject(error));
        } else {
          resolve('Not found columns data')
        }
        
      });
    },
    getOrderColumnsFx({ commit }){
      return new Promise((resolve, reject) => {
          axiosIns
          .post(`getOrderColumnsFx`,{type: "FxExecuted"})
          .then((res) => {
            if(res.info == 'found'){
              commit("_saveOrderColumnsFx", res.data.data[0]);
              resolve(res.data.info);
            } else {
              resolve(res.data.info);
            }
          })
          .catch((error) => reject(error));
      });
    },

    saveOrderColumnsSwapFuture({ commit }, columnsData) {
      return new Promise((resolve, reject) => {
        if(columnsData){
          axiosIns
          .post(`saveOrderColumnsSwapFuture`,{columnsData:columnsData, type: "SwapFuture"})
          .then((response) => {
            resolve(response);
            commit("_saveOrderColumnsSwapFuture", columnsData);
          })
          .catch((error) => reject(error));
        } else {
          resolve('Not found columns data')
        }
        
      });
    },
    getOrderColumnsSwapFuture({ commit }){
      return new Promise((resolve, reject) => {
          axiosIns
          .post(`getOrderColumnsSwapFuture`,{type: "SwapFuture"})
          .then((res) => {
            if(res.info == 'found'){
              commit("_saveOrderColumnsSwapFuture", res.data.data[0]);
              resolve(res.data.info);
            } else {
              resolve(res.data.info);
            }
          })
          .catch((error) => reject(error));
      });
    },

    saveOrderColumnsFxOption({ commit }, columnsData) {
      return new Promise((resolve, reject) => {
        if(columnsData){
          axiosIns
          .post(`saveOrderColumnsFxOption`,{columnsData:columnsData, type: "FxOption"})
          .then((response) => {
            resolve(response);
            commit("_saveOrderColumnsFxOption", columnsData);
          })
          .catch((error) => reject(error));
        } else {
          resolve('Not found columns data')
        }
        
      });
    },
    getOrderColumnsFxOption({ commit }){
      return new Promise((resolve, reject) => {
          axiosIns
          .post(`getOrderColumnsFxOption`,{type: "FxOption"})
          .then((res) => {
            if(res.info == 'found'){
              commit("_saveOrderColumnsFxOption", res.data.data[0]);
              resolve(res.data.info);
            } else {
              resolve(res.data.info);
            }
          })
          .catch((error) => reject(error));
      });
    },

    saveOrderColumnsDeposit({ commit }, columnsData) {
      return new Promise((resolve, reject) => {
        if(columnsData){
          axiosIns
          .post(`saveOrderColumnsDeposit`,{columnsData:columnsData, type: "Deposit"})
          .then((response) => {
            resolve(response);
            commit("_saveOrderColumnsDeposit", columnsData);
          })
          .catch((error) => reject(error));
        } else {
          resolve('Not found columns data')
        }
        
      });
    },
    getOrderColumnsDeposit({ commit }){
      return new Promise((resolve, reject) => {
          axiosIns
          .post(`getOrderColumnsDeposit`,{type: "Deposit"})
          .then((res) => {
            if(res.info == 'found'){
              commit("_saveOrderColumnsDeposit", res.data.data[0]);
              resolve(res.data.info);
            } else {
              resolve(res.data.info);
            }
          })
          .catch((error) => reject(error));
      });
    },
  },

  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState()],
});
