import Vue from "vue";
import BootstrapVue, { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import { IconsPlugin, BootstrapVueIcons } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import axios from "@axios";
/*import '@/libs/acl';*/
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import useJwt from "@/auth/jwt/useJwt";
import Swal from "sweetalert2/dist/sweetalert2";
//Permissions mixin
import Permissions from "./mixins/Permissions";
Vue.mixin(Permissions);

// Bcrypt password mixin
import BcryptPassword from "./mixins/BcryptPassword";
Vue.mixin(BcryptPassword);

// Access Right Permission
import AccessRight from "./mixins/AccessRight";
Vue.mixin(AccessRight);

import VueGoodTablePlugin from "vue-good-table";

// import the styles
import "vue-good-table/dist/vue-good-table.css";

Vue.use(VueGoodTablePlugin);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

// Define a function to check if the response indicates logout
function checkLogout(response) {
  if (response.data.check_ip == false) {
    // Perform logout action
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
    localStorage.removeItem("userData");
    router.push({ name: "auth-login" });
  }
}

// Add a global response interceptor
axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    checkLogout(response);
    // Check if the response indicates success (status code 2xx)
    if (response.status >= 200 && response.status < 300) {
      return response; // Return the response if it's successful
    } else {
      // Display an error message
      // You can replace this with your preferred method of displaying error messages (e.g., toast, alert, etc.)
      Swal.fire({
        title: "Unexpected Error",
        text:
          "Process failed. Please contact support or try again. Error code: " +
          " " +
          response.status,
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "OK",
      });
      return Promise.reject(response); // Reject the promise to handle the error further downstream
    }
  },
  function(error) {
    // Do something with response error
    if (error.response) {
      checkLogout(error.response);
    }
    Swal.fire({
      title: "Error",
      text:
        "Process failed. Please contact support or try again. Error message: " +
        " " +
        (error.response.data.message.length > 1
          ? error.response.data.message
          : error.response.data.exception),
      icon: "error",
      showCancelButton: false,
      confirmButtonText: "OK",
    });

    return Promise.reject(error);
  }
);

export default router;
