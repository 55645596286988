export default [
    {
      path: '/report/portfolios',
      name: 'report-portfolios',
      component: () => import('@/views/reports/portfolio/Portfolio.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
    {
        path: '/report/statements',
        name: 'report-statements',
        component: () => import('@/views/reports/statement/Statement.vue'),
        meta: {
          // pageTitle: 'Settings',
          // breadcrumb: [
          //   {
          //     text: 'Settings',
          //     active: true,
          //   },
          // ],
          action: 'read',
          Permission: 'no_permission_required',
        },
      },
  
  ];
  