export default [
    {
      path: '/bonds',
      name: 'bonds',
      component: () => import('@/views/bond/Bonds.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
    {
      path: '/bond/amend-details/:id',
      name: 'bond-amend-details',
      component: () => import('@/views/bond/amendDetails/AmendDetails.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },

    {
      path: '/bond/coupon-payments',
      name: 'coupon-payments',
      component: () => import('@/views/bond/CouponPayments.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },




    
  
  ];
  