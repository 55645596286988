export default [
    {
      path: '/deposits',
      name: 'deposits',
      component: () => import('@/views/deposit/Deposit.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
    // {
    //   path: '/deposit/amend-details/:id',
    //   name: 'deposit-amend-details',
    //   component: () => import('@/views/deposit/amendDetails/AmendDetails.vue'),
    //   meta: {
    //     // pageTitle: 'Settings',
    //     // breadcrumb: [
    //     //   {
    //     //     text: 'Settings',
    //     //     active: true,
    //     //   },
    //     // ],
    //     action: 'read',
    //     Permission: 'no_permission_required',
    //   },
    // },
  
  ];
  