export default [
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/settings/Settings.vue"),
    meta: {
      // pageTitle: 'Settings',
      // breadcrumb: [
      //   {
      //     text: 'Settings',
      //     active: true,
      //   },
      // ],
      resource: "settings",
      action: "read",
      Permission: "no_permission_required",
    },
  },

  {
    path: "/settings/admin-panel",
    name: "settings-admin-panel",
    component: () => import("@/views/settings/admin-panel/AdminPanel.vue"),

    meta: {
      Permission: "no_permission_required",
    },
  },
  {
    path: "/settings/admin-panel/manage-teams",
    name: "settings-manage-teams",
    component: () => import("@/views/settings/admin-panel/teams/TeamList.vue"),

    meta: {
      Permission: "no_permission_required",
    },
  },
  {
    path: "/settings/admin-panel/manage-currencies",
    name: "settings-manage-currencies",
    component: () =>
      import("@/views/settings/admin-panel/currencies/Currencies.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
  {
    path: "/settings/admin-panel/manage-cost-centers",
    name: "settings-manage-cost-centers",
    component: () =>
      import("@/views/settings/admin-panel/cost-centers/CostCenters.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/settings/admin-panel/manage-tax-rates",
    name: "settings-manage-tax-rates",
    component: () =>
      import("@/views/settings/admin-panel/tax-rates/TaxRates.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/settings/admin-panel/manage-historical-prices",
    name: "settings-manage-historical-prices",
    component: () =>
      import(
        "@/views/settings/admin-panel/historical-prices/HistoricalPrices.vue"
      ),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/settings/admin-panel/manage-commissions",
    name: "settings-manage-commissions",
    component: () =>
      import("@/views/settings/admin-panel/commissions/Commissions.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/settings/admin-panel/manage-users",
    name: "settings-manage-users",
    component: () => import("@/views/settings/admin-panel/users/Users.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
  {
    path: "/settings/admin-panel/manage-intermediaries",
    name: "settings-manage-intermediaries",
    component: () =>
      import("@/views/settings/admin-panel/intermediaries/Intermediary.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
  {
    path: "/settings/admin-panel/manage-default-tickets",
    name: "settings-manage-default-tickets",
    component: () =>
      import("@/views/settings/admin-panel/tickets/TicketVisibility.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
  {
    path: "/settings/admin-panel/manage-emails",
    name: "settings-manage-emails",
    component: () => import("@/views/settings/admin-panel/emails/Email.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
  {
    path: "/settings/admin-panel/manage-holidays",
    name: "settings-manage-holidays",
    component: () =>
      import("@/views/settings/admin-panel/holidays/Holiday.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
  {
    path: "/settings/admin-panel/action-timeline",
    name: "settings-action-timeline",
    component: () =>
      import("@/views/settings/admin-panel/timeline/Timeline.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/settings/user-role",
    name: "settings-user-role",
    component: () =>
      import("@/views/settings/Permissions/role-list/RoleList.vue"),

    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/settings/personal-settings",
    name: "settings-personal-settings",
    component: () =>
      import("@/views/settings/personal-settings/PersonalSettings.vue"),

    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/settings/admin-panel/manage-future-specs",
    name: "settings-manage-future-specs",
    component: () =>
      import(
        "@/views/settings/admin-panel/future-contract-specs/FutureContractSpecs.vue"
      ),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/settings/admin-panel/manage-cpty-trader",
    name: "settings-manage-cpty-trader",
    component: () =>
      import("@/views/settings/admin-panel/cpty-trader/CptyTrader.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/settings/admin-panel/acting-capacity",
    name: "settings-manage-acting-capacity",
    component: () =>
      import("@/views/settings/admin-panel/acting-capacity/ActingCapacity.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },
];
