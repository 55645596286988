export default [
    {
      path: '/fx-options',
      name: 'fx-options',
      component: () => import('@/views/fx-options/FXOptions.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings',
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
    {
      path: '/fx-option/amend-details/:id',
      name: 'fx-option-amend-details',
      component: () => import('@/views/fx-options/amendDetails/AmendDetails.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings', 
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
    {
      path: '/fx-option/selected-same-options-client/:id',
      name: 'fx-option-same-options-client',
      component: () => import('@/views/fx-options/bringSameOptions/BringSameOptions.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings', 
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
    {
      path: '/fx-option/selected-paired-trades/:id',
      name: 'fx-option-selected-paired-trades',
      component: () => import('@/views/fx-options/getPairedTrades/PairedTrades.vue'),
      meta: {
        // pageTitle: 'Settings',
        // breadcrumb: [
        //   {
        //     text: 'Settings', 
        //     active: true,
        //   },
        // ],
        action: 'read',
        Permission: 'no_permission_required',
      },
    },
  
  ];